import React from "react";
import { Box, Typography } from "@mui/material";
import connection from "../public/connection.png";
import Image from "next/image";
import makeStyles from "@mui/styles/makeStyles";
import TrustPilot from "components/TrustPilot";
import Icon5stars from "components/Icon5stars";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
// import Lottie from "react-lottie";
// import loginAnim from "../public/serrure-carre.json";

const LongText = ({ text }) => {
  return (
    <>
      {text.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          {index !== text.split("\n").length - 1 && <br />}
        </span>
      ))}
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "white",
    },
  },
  background: {
    backgroundImage: "url(/reflet.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80%",
    position: "absolute",
    backgroundPosition: "0 0",
  },
  display: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& img": {
      maxWidth: "100%",
      height: "auto",
    },
  },
  // margin: {
  //   [theme.breakpoints.up("md")]: {
  //     marginLeft: "20%",
  //   },
  // },
  title: {
    fontSize: "58px",
    fontWeight: 900,
    background: theme.palette.primary.main,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
}));

export default function LoginLayout(props) {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { locale } = useRouter();
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: loginAnim,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };
  return (
    <div className={classes.background}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="stretch"
        sx={{
          minHeight: "100vh",
          width: "100vw",
          "& *": { boxSizing: "border-box" },
        }}
      >
        <Box
          padding="100px 80px 80px 80px"
          display="flex"
          minHeight="100vh"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          minWidth="40%"
          boxShadow="0 0 30px #e9f0fe"
          borderRight="1px solid #e9f0fe"
          zIndex="9"
          sx={{ minHeight: "100%" }}
        >
          <Box sx={{ maxWidth: "500px" }}>
            <Typography
              className={classes.title}
              variant="h4"
              color="primary"
              sx={{ mb: 0.5 }}
            >
              {props.title}
            </Typography>
            <Typography variant="h5" color="#54585b" sx={{ mb: 4 }}>
              {props.subtitle}
            </Typography>
            {props.children}
          </Box>
        </Box>
        <Box
          className={classes.display}
          padding="80px 0 80px 80px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flexGrow="1"
        >
          {" "}
          <Box mr="80px">
            <Box display="flex" justifyContent="center" mb={6}>
              <Box
                display="flex"
                padding="12px 25px 12px 20px"
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #e4e4e4",
                  boxShadow: "0 0px 30px #d1cadd94",
                  "& svg": {
                    maxWidth: "120px",
                  },
                }}
              >
                <Box
                  pr={2}
                  textAlign="right"
                  sx={{
                    borderRight: "1px solid #e4e4e4",
                    "& svg": {
                      marginTop: "-10px",
                    },
                  }}
                >
                  <TrustPilot />
                  <Typography lineHeight="1" sx={{ marginTop: "-10px" }}>
                    {"150 " + t("reviews")}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  pl={2}
                  sx={{ "& svg": { marginBottom: "0px" } }}
                >
                  <Typography
                    lineHeight="1"
                    sx={{ fontWeight: "bold", marginTop: "9px" }}
                  >
                    4,9 / 5
                  </Typography>
                  <Icon5stars />
                </Box>
              </Box>
            </Box>
            {props?.brand == "faaasterpro" ? (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  maxWidth={locale == "en" ? "840px" : "800px"}
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography
                    variant="caption"
                    textAlign="center"
                    sx={{ mb: 2, fontSize: "18px", textAlign: "center" }}
                  >
                    <LongText text={t("review-pro")} />
                  </Typography>
                  <Box display="flex">
                    <Typography
                      variant="h5"
                      sx={{ mb: 4, fontSize: "18px", textAlign: "center" }}
                    >
                      Fabrice
                    </Typography>
                    <Typography sx={{ margin: "0 8px" }}>{" - "}</Typography>
                    <Typography
                      variant="h5"
                      color="black"
                      sx={{ mb: 4, fontSize: "18px", textAlign: "center" }}
                    >
                      {t("cto")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  maxWidth="600px"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography
                    variant="caption"
                    textAlign="center"
                    sx={{ mb: 2, fontSize: "18px", textAlign: "center" }}
                  >
                    {t("review-io")}
                  </Typography>
                  <Box display="flex">
                    <Typography
                      variant="h5"
                      sx={{ mb: 4, fontSize: "18px", textAlign: "center" }}
                    >
                      Nicolas Rivière
                    </Typography>
                    <Typography sx={{ margin: "0 8px" }}>{" - "}</Typography>
                    <Typography
                      variant="h5"
                      color="black"
                      sx={{ mb: 4, fontSize: "18px", textAlign: "center" }}
                    >
                      Freelance
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {/* <Lottie options={defaultOptions} height={400} width={400} /> */}
          <Image
            src={connection}
            alt="Logo"
            style={{ width: "70%", marginLeft: "30%" }}
          />
        </Box>
      </Box>
    </div>
  );
}
